export class Header {
    constructor()
    {
        this.burger()
        this.scrollHandler()
        this.language()
    }

    initBurger()
    {
        const burger = document.querySelector('#burger')
        const menus = document.querySelectorAll('.header-nav__nav')
        let breakpoint = burger.getAttribute('data-breakpoint');

        if (window.matchMedia("(max-width: " + breakpoint + "px)").matches) {
            burger.classList.remove('hidden');
            burger.classList.add('show');
            menus.forEach(menu => {
                menu.style.display = "none";
            });
        } else {
            burger.classList.remove('show');
            burger.classList.add('hidden');
            menus.forEach(menu => {
                menu.style.display = "block";
            });
        }
    }

    burger()
    {
        this.header = document.querySelector('body header')
        if (this.header) {
            const nav = document.querySelector('.header-nav__burger')
            const burger = document.querySelector('#burger')

            if (burger) {
                this.initBurger();

                burger.addEventListener('click', evt => {
                    evt.preventDefault()
                    burger.classList.toggle('active')
                    nav.classList.toggle('expanded')
                })

                window.addEventListener('resize', evt => {
                      this.initBurger();
                })
            }

            const burgerSubmenus = document.querySelectorAll(".header-nav__burger .menu > .menu-item-has-children")

            burgerSubmenus.forEach(t => {
                let trigger = t.querySelectorAll('a')[0].offsetHeight
                let height = t.querySelector('.sub-menu').offsetHeight + trigger + 20

                t.style.setProperty('--max-height', height + 'px')
                t.style.setProperty('--init-height', trigger + 'px')

                t.querySelector('a').addEventListener('click', e => {
                    e.preventDefault();
                    let submenus = document.querySelectorAll(".header-nav__burger .menu > .menu-item-has-children");
                    submenus.forEach(s => {
                        if (s === t && s.classList.contains('open')) {
                            let doNothing = null
                        } else {
                            s.classList.remove('open')
                        }
                    })

                t.classList.toggle('open')
                })
            })
        }
    }


    scrollHandler()
    {
        let headerHeight = document.querySelector('header.header-wrap').offsetHeight;
        if (document.documentElement.scrollTop || document.body.scrollTop > headerHeight) {
            this.header.classList.add('scrolled')
        }

        document.addEventListener('scroll', () => {
            let height = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
            if (height > headerHeight && !this.header.classList.contains('scrolled')) {
                this.header.classList.toggle('scrolled')
            }
            if (height <= headerHeight && this.header.classList.contains('scrolled')) {
                this.header.classList.toggle('scrolled')
            }
        })

    }

    language()
    {
        document.querySelectorAll('.lang-item a').forEach(lang => {
            lang.innerHTML = lang.innerHTML.slice(0, 2);
        });
    }
}
