import Lenis from "lenis";

export class Scroll {
    constructor()
    {
        const lenis = new Lenis();

        function raf(time)
        {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }

        requestAnimationFrame(raf);
    }
}
