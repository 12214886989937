import Swiper, {Pagination, Autoplay, Navigation} from "swiper";

export class Slider {
    constructor()
    {
        document.querySelectorAll('*[slider]').forEach(slider => {
            this.registerSlider(slider)

        })
    }

    registerSlider(slider)
    {
        const getIndex = (id) => {
            const c = document.querySelector(`.swiper-slide[slide-name="${id}"]`);
            if (!c) {
                return 0; // Retourne 0 si l'élément n'est pas trouvé
            } else {
                const yOffset = -40;
                const y = document.getElementById(slider.id).getBoundingClientRect().top + window.scrollY + yOffset;
                window.scrollTo({
                    top: y,
                    behavior: 'smooth'});
            }

            const parent = c.parentNode;
            return Array.from(parent.children).indexOf(c); // Trouver l'index de l'élément dans son parent
        };

        let anchor = window.location.hash.substr(1);

        const modules = [Navigation, Autoplay]

      // Récupérer les paramètres de chaque slider
        let name = slider.id;
        let slidesPerView = parseInt(slider.getAttribute('per-view'), 10);
        let spaceBetween = parseInt(slider.getAttribute('space-between'), 10);
        let autoplay = slider.getAttribute('autoplay');
        let autoplayDelay = slider.getAttribute('autoplay-delay');

        const autoplayConfig = autoplay === 'true' ? {
            delay: parseInt(autoplayDelay, 10) || 4000,
            disableOnInteraction: false
        } : false;

        const swiper = new Swiper('#' + name, {
            modules: modules,
            loop: false,
            spaceBetween: spaceBetween,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            autoplay: autoplayConfig,
            initialSlide: getIndex(anchor),
            breakpoints: {
                768: {
                    slidesPerView: 1,
                },
                1024: {
                    slidesPerView: slidesPerView,
                }
            }
        });
    }
}
