export class Content {
    constructor()
    {
        this.init();
    }

    init()
    {
        document.querySelectorAll('.s-content .content').forEach(content => {
            let text =  content.querySelector('.content-text');
            content.style.setProperty('--height', text.scrollHeight + 'px');
        })
    }

}
