import '@css/main.scss';
import '@js/bootstrap';

import {Header} from '@js/components/Header';
import {Timeline} from '@js/components/Timeline';
import {Slider} from '@js/components/Slider';
import {Post} from '@js/components/Post';
import {Content} from '@js/components/Content';
import {Animation} from '@js/utils/Animation';
import {Scroll} from '@js/utils/Scroll';

window.onload = () => {
    new Header()
    new Slider()
    new Post()
    new Timeline()
    new Content()
    new Animation()
    new Scroll()
}
