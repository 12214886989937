export class Post {
    constructor()
    {
        this.init()
        this.extend()
        this.menu()
    }

    init()
    {
        document.querySelectorAll('.c-post').forEach(post => {
            post.style.setProperty('--postHeight', post.clientHeight + 'px');
            let text =  post.querySelector('.c-post__text');
            text.style.setProperty('--height', text.scrollHeight + 'px');
        })
    }

    extend()
    {
        document.querySelectorAll('.c-post').forEach(post => {
            post.querySelectorAll('.c-post__btn').forEach(btn => {
                btn.addEventListener('click', function (e) {
                    e.preventDefault();
                    let remove = false;
                    if (post.classList.contains('c-post--extended')) {
                        remove = true;
                    }
                    document.querySelectorAll('.c-post').forEach(p => {
                        p.classList.remove('c-post--extended');
                    });
                    if (remove) {
                        post.classList.remove('c-post--extended');
                    } else {
                        post.classList.add('c-post--extended');
                    }
                });
            })
        })
    }

    menu()
    {
        let anchor = window.location.hash.substr(1);
        let targetPost = document.getElementById(anchor);
        if (targetPost) {
            targetPost.classList.add('c-post--extended');
        }
    }

}
