import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export class Timeline {
    constructor()
    {
        this.init()
    }
    init()
    {
        gsap.registerPlugin(ScrollTrigger)

        gsap.to('.timeline-progress', {
            '--height': '50vh',
            scrollTrigger: {
                trigger: '.timeline',
                start: 'top-=200px center',
                end: 'top+=400px center',
                scrub: true,
                toggleActions: 'restart none none reverse',
            }
        })

        gsap.utils.toArray('.timeline__item').forEach(item => {
            ScrollTrigger.create({
                trigger: item,
                start: 'center center-=200px',
                onEnter: () => {
                    item.style.color = '#043E85'
                    item.style.setProperty('--color-content', '#FFFFFF')
                    item.style.setProperty('--color-circle', '#043E85')
                },
                onLeave: () => {
                    item.style.color = '#043E85'
                    item.style.setProperty('--color-content', '#FFFFFF')
                    item.style.setProperty('--color-circle', '#043E85')
                },
                onEnterBack: () => {
                    item.style.color = '#043E85'
                    item.style.setProperty('--color-content', '#FFFFFF')
                    item.style.setProperty('--color-circle', '#043E85')
                },
                onLeaveBack: () => {
                    item.style.color = '#9bb2ce'
                    item.style.setProperty('--color-content', '#9bb2ce')
                    item.style.setProperty('--color-circle', '#9bb2ce')
                }
            })
        })

    }

}
