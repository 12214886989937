import {gsap} from "gsap";
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import Aos from 'aos'

export class Animation {
    constructor()
    {
        this.config()
        this.circle()
        Aos.init({
            offset: 250,
            easing: 'ease-out'
        })
    }

    config()
    {
        gsap.registerPlugin(ScrollTrigger);
    }

    circle()
    {
        window.addEventListener('scroll', function () {
            let scrollPosition = window.pageYOffset;
            let parallaxSpeed = 0.3;

            let parallaxElements = document.querySelectorAll('.animated-circle');
            if (parallaxElements) {
                parallaxElements.forEach(element => {
                    let elementOffsetTop = element.offsetTop;
                    let parallaxOffset = (scrollPosition - elementOffsetTop) * parallaxSpeed;

                    element.style.setProperty('--scroll', parallaxOffset + 'px');
                });
            }
        });
    }

}
